export default {
  北京: [116.395645038, 39.9299857781],
  上海: [121.487899486, 31.24916171],
  天津: [117.210813092, 39.1439299033],
  重庆: [106.530635013, 29.5446061089],
  广州: [113.307649675, 23.1200491021],
  深圳: [114.025973657, 22.5460535462],
  武汉: [114.316200103, 30.5810841269],
  南京: [118.778074408, 32.0572355018],
  沈阳: [123.432790922, 41.8086447835],
  长春: [125.313642427, 43.8983376071],
  大连: [121.593477781, 38.9487099383],
  成都: [104.067923463, 30.6799428454],
  西安: [108.953098279, 34.2777998978],
  哈尔滨: [126.657716855, 45.7732246332],
  苏州: [120.619907115, 31.317987368],
  郑州: [113.64964385, 34.7566100641],
  昆明: [102.714601139, 25.0491531005],
  杭州: [120.219375416, 30.2592444615],
  佛山: [113.134025635, 23.0350948405],
  长沙: [112.979352788, 28.2134782309],
  宁波: [121.579005973, 29.8852589659],
  无锡: [120.305455901, 31.5700374519],
  南昌: [115.893527546, 28.6895780001],
  兰州: [103.823305441, 36.064225525],
  青岛: [120.384428184, 36.1052149013],
  淮安: [119.030186365, 33.6065127393],
  福州: [119.330221107, 26.0471254966],
  东莞: [113.763433991, 23.0430238154],
  南宁: [108.297233556, 22.8064929356],
  合肥: [117.282699092, 31.8669422607],
  石家庄: [114.522081844, 38.0489583146],
  贵阳: [106.709177096, 26.6299067414],
  厦门: [118.103886046, 24.4892306125],
  珠海: [113.562447026, 22.2569146461],
  香港: [114.160676, 22.343916],
  台北: [121.523946, 25.042619],
  金华: [119.652254, 29.086182]
}
