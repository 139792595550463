export default [
  { name: '北京', value: 50, show: false, cityCode: 110100 },
  { name: '上海', value: 50, show: false, cityCode: 0 },
  { name: '天津', value: 50, show: false, cityCode: 0 },
  { name: '重庆', value: 50, show: false, cityCode: 0 },
  { name: '广州', value: 50, show: false, cityCode: 0 },
  { name: '深圳', value: 50, show: false, cityCode: 0 },
  { name: '武汉', value: 50, show: false, cityCode: 0 },
  { name: '南京', value: 50, show: false, cityCode: 0 },
  { name: '沈阳', value: 50, show: false, cityCode: 0 },
  { name: '长春', value: 50, show: false, cityCode: 0 },
  { name: '大连', value: 50, show: false, cityCode: 0 },
  { name: '成都', value: 50, show: false, cityCode: 510100 },
  { name: '西安', value: 50, show: false, cityCode: 0 },
  { name: '苏州', value: 50, show: false, cityCode: 0 },
  { name: '郑州', value: 50, show: false, cityCode: 0 },
  { name: '昆明', value: 50, show: false, cityCode: 530100 },
  { name: '杭州', value: 50, show: false, cityCode: 330100 },
  { name: '佛山', value: 50, show: false, cityCode: 0 },
  { name: '长沙', value: 50, show: false, cityCode: 0 },
  { name: '宁波', value: 50, show: false, cityCode: 330200 },
  { name: '无锡', value: 50, show: false, cityCode: 320200 },
  { name: '南昌', value: 50, show: false, cityCode: 0 },
  { name: '兰州', value: 50, show: false, cityCode: 0 },
  { name: '青岛', value: 50, show: false, cityCode: 370200 },
  { name: '淮安', value: 50, show: false, cityCode: 0 },
  { name: '福州', value: 50, show: false, cityCode: 0 },
  { name: '东莞', value: 50, show: false, cityCode: 0 },
  { name: '南宁', value: 50, show: false, cityCode: 0 },
  { name: '合肥', value: 50, show: false, cityCode: 0 },
  { name: '贵阳', value: 50, show: false, cityCode: 0 },
  { name: '厦门', value: 50, show: false, cityCode: 0 },
  { name: '珠海', value: 50, show: false, cityCode: 0 },
  { name: '哈尔滨', value: 50, show: false, cityCode: 0 },
  { name: '石家庄', value: 50, show: false, cityCode: 0 },
  { name: '香港', value: 50, show: false, cityCode: 0 },
  { name: '台北', value: 50, show: false, cityCode: 0 }
]
