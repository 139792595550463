<template>
  <Row>
    <i-col span="24">
        <div style="width:100%;" id="home_page_map"></div>
    </i-col>
  </Row>
</template>

<script>
import * as echarts from 'echarts'
import geoData from './get-geography-value.js'
import cityData from './get-city-value.js'

export default {
  name: 'homeMap',
  data () {
    return {
      map: null
    }
  },
  mounted () {
    const chinaJson = require('./china.json')
    const that = this

    document.getElementById('home_page_map').style.height = document.body.clientHeight - 50 + 'px'
    that.map = echarts.init(document.getElementById('home_page_map'))
    echarts.registerMap('china', chinaJson)
    // that.initMap()
    that.map.on('click', function (param) {
      if (param.value && param.value[2] === 100) {
        // 切换显示出线路图
        that.$store.commit('set_adx_currentCityCode', param.value[3])
        setTimeout(() => {
          that.$store.commit('set_adx_update_map', new Date())
        }, 500)
      }
    })

    window.addEventListener('resize', function () {
      that.map.resize()
    })
  },
  methods: {
    convertData (data) {
      const res = []
      const len = data.length
      for (var i = 0; i < len; i++) {
        var geoCoord = geoData[data[i].name]
        if (geoCoord) {
          var resValue = geoCoord.concat(data[i].value)
          resValue = resValue.concat(data[i].cityCode)
          resValue = resValue.concat(data[i].surplus)
          res.push({
            name: data[i].name,
            value: resValue
          })
        }
      }
      return res
    },
    initMap (purchaseCity, allowCity) {
      this.map.setOption({
        backgroundColor: '#1A1A1A',
        title: {
          text: '全国地铁城市分布图',
          subtext: '请点击地图红色或蓝色圆形图标查看城市地铁线路图',
          left: 'center',
          top: 50
        },
        geo: {
          map: 'china',
          top: 10,
          emphasis: {
            label: {
              show: false
            },
            itemStyle: {
              areaColor: '#0f3360'
            }
          },
          itemStyle: {
            areaColor: '#132230',
            borderColor: 'gray'
          }
        },
        grid: {
          top: 0,
          left: '2%',
          right: '2%',
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            axisName: '已采购的城市',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: this.convertData(purchaseCity),
            symbolSize: function (val) {
              return val[2] / 7
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              formatter: '{b},{@[4]}',
              position: 'bottom',
              show: true
            },
            itemStyle: {
              color: '#e84c3d',
              shadowBlur: 10,
              shadowColor: '#333'
            },
            zlevel: 1
          },
          {
            axisName: '允许采购的城市',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: this.convertData(allowCity),
            symbolSize: function (val) {
              return val[2] / 7
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              formatter: '{b},{@[4]}',
              position: 'bottom',
              show: true
            },
            itemStyle: {
              color: '#2d8cf0',
              shadowBlur: 10,
              shadowColor: '#2db7f5'
            },
            zlevel: 2
          },
          {
            axisName: '其余有地铁的城市',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.convertData(cityData),
            symbolSize: function (val) {
              return val[2] / 7
            },
            emphasis: {
              label: {
                formatter: '{b}',
                position: 'right',
                show: true
              }
            },
            itemStyle: {
              color: '#B0B0B0'
            }
          }
        ]
      })
    }
  },
  computed: {
    ChinaMapData () {
      return this.$store.state.adx.ChinaMapData
    },
    updateChinaMap () {
      return this.$store.state.adx.updateChinaMap
    }
  },
  watch: {
    updateChinaMap () {
      this.initMap(this.ChinaMapData.purchaseCityData, this.ChinaMapData.allowCityData)
    }
  }
}
</script>
